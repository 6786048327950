import React, { Component, ReactElement } from 'react';
import SinglePublication from './SinglePublication/SinglePublication';
import PublicationClient from '../../infrastructure/Publication/Client/Client';
import Module from '../../application/Service/Module';
import qs from 'querystring';
import Publication from '../../domain/Publication/Publication';

interface SinglePublicationPreviewProps {
    pageContext: Record<string, any>;
}

interface SinglePublicationPreviewState {
    loading: boolean;
    error: null | string;
    publicationDataEn: Publication | null;
    publicationDataDe: Publication | null;
}

export default class SinglePublicationPreview extends Component<SinglePublicationPreviewProps, SinglePublicationPreviewState>
{
    public state: SinglePublicationPreviewState = {
        loading: true,
        error: null,
        publicationDataDe: null,
        publicationDataEn: null,
    }

    public componentDidMount(): void
    {
        this.loadData();
    }

    public render(): ReactElement
    {
        if (this.state.loading)
        {
            return (
                <div
                    style={{
                        textAlign: 'center',
                        margin: '0 auto',
                        fontWeight: 'bold',
                        marginTop: '8rem',
                        width: '100%',
                        fontSize: '1.4rem',
                    }}
                >
                    Generating Preview!
                </div>
            );
        }

        if (this.state.error)
        {
            return (
                <div
                    style={{
                        textAlign: 'center',
                        margin: '0 auto',
                        fontWeight: 'bold',
                        marginTop: '8rem',
                        width: '100%',
                        fontSize: '1.4rem',
                    }}
                >
                    {this.state.error}
                </div>
            );
        }

        return (
            <SinglePublication
                {...this.props}
                // @todo [refactor] fix typing!
                // @ts-ignore
                pageContext={{
                    ...this.props.pageContext,
                    publicationDataDe: this.state.publicationDataDe!.getRepresenter().forSinglePublicationPage(),
                    publicationDataEn: this.state.publicationDataEn!.getRepresenter().forSinglePublicationPage(),
                }}
            />
        );
    }

    public async loadData(): Promise<void>
    {
        // @todo [refactor] fix this
        const query = Module.get().location.search;
        const id = qs.parse(query.replace('?', '')).id;

        if (!id)
        {
            this.setState({
                error: 'No id provided',
            });

            return;
        }

        const client = new PublicationClient();
        const publicationDe = await client.getPublicationById(id as string, 'de');
        const publicationEn = await client.getPublicationById(id as string, 'en');

        this.setState({
            loading: false,
            publicationDataEn: publicationEn,
            publicationDataDe: publicationDe,
        });
    }
}
