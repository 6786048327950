import Publication from '../../../domain/Publication/Publication';
import { PublicationInterface } from '../../../domain/Publication/PublicationInterface';

class Representer
{
    public constructor(private publication: Publication)
    {
    }

    public forSinglePublicationPage(): PublicationInterface
    {
        return {
            id: this.publication.getId(),
            title: this.publication.getTitle(),
            description: this.publication.getDescription(),
            tags: this.publication.getTags(),
            publishingOrganizationName: this.publication.getPublishingOrganizationName(),
            publishToGlaubensfutterDate: this.publication.getPublishToGlaubensfutterDate(),
            articleBodyMarkdown: this.publication.getArticleBodyMarkdown(),
            linkToOriginalWork: this.publication.getLinkToOriginalWork(),
            translatedBy: this.publication.getTranslatedBy(),
            linkToPublisherOrganizationSite: this.publication.getLinkToPublisherOrganizationSite(),
            videoSrc: this.publication.getVideoSrc(),
            audioSrc: this.publication.getAudioSrc(),
            originalAuthor: this.publication.getOriginalAuthor(),
            originalPublishDate: this.publication.getOriginalPublishDate(),
            slug: this.publication.getSlug(),
            status: this.publication.getStatus(),
            image: this.publication.getImage(),
            estimatedReadingTime: this.publication.getEstimatedReadingTime(),
        };
    }

    public trimmedDescriptionByLength(length: number): string
    {
        const description = this.publication.getDescription();

        if (!description )
        {
            return '';
        }

        const trimmed = description.slice(0, length);

        if (trimmed.length !== description.length)
        {
            return trimmed + '...';
        }

        return trimmed;
    }
}

export default Representer;
