import Publication from './Publication';
import { PublicationFromApiInterface } from './PublicationFromAPI';

class Factory
{
    public fromApiFullStructure(publication: PublicationFromApiInterface): Publication
    {
        return new Publication(
            publication._id,
            publication.title,
            publication. slug,
            publication.translatedBy,
            publication.status,
            publication.articleBodyMarkdown,
            publication.description,
            publication.tags,
            publication.videoSrc,
            publication.audioSrc,
            publication.linkToPublisherOrganizationSite,
            publication.linkToOriginalWork,
            publication.publishingOrganizationName,
            publication.originalAuthor,
            publication.publishToGlaubensfutterDate,
            publication.originalPublishDate,
            publication.image,
            publication.estimatedReadingTime,
        );
    }
}

export default Factory;
