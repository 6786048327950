import Factory from './Factory';
import Representer from '../../infrastructure/Publication/Representer/Representer';

class Publication
{
    public constructor(
        private id: string,
        private title: string,
        private slug: string,
        private translatedBy: string,
        private status: string | null,
        private articleBodyMarkdown: string,
        private description: string,
        private tags: any[],
        private videoSrc: string | null,
        private audioSrc: string | null,
        private linkToPublisherOrganizationSite: string,
        private linkToOriginalWork: string,
        private publishingOrganizationName: string,
        private originalAuthor: string,
        private publishToGlaubensfutterDate: number,
        private originalPublishDate: number,
        private image: string|null,
        private estimatedReadingTime: number|null,
    )
    {}

    public static Factory(): Factory
    {
        return new Factory();
    }

    public getId(): string
    {
        return this.id;
    }

    public getTitle(): string
    {
        return this.title;
    }

    public getSlug(): string
    {
        return this.slug;
    }

    public getTranslatedBy(): string
    {
        return this.translatedBy;
    }

    public getStatus(): string | null
    {
        return this.status;
    }

    public getArticleBodyMarkdown(): string
    {
        return this.articleBodyMarkdown;
    }

    public getDescription(): string
    {
        return this.description;
    }

    public getTags(): any[]
    {
        return this.tags;
    }

    public getVideoSrc(): string | null
    {
        return this.videoSrc;
    }

    public getAudioSrc(): string | null
    {
        return this.audioSrc;
    }

    public getLinkToPublisherOrganizationSite(): string
    {
        return this.linkToPublisherOrganizationSite;
    }

    public getLinkToOriginalWork(): string
    {
        return this.linkToOriginalWork;
    }

    public getPublishingOrganizationName(): string
    {
        return this.publishingOrganizationName;
    }

    public getOriginalAuthor(): string
    {
        return this.originalAuthor;
    }

    public getPublishToGlaubensfutterDate(): number
    {
        return this.publishToGlaubensfutterDate;
    }

    public getOriginalPublishDate(): number
    {
        return this.originalPublishDate;
    }

    public getImage(): string|null
    {
        return this.image;
    }

    public getEstimatedReadingTime(): number|null
    {
        return this.estimatedReadingTime;
    }

    public getRepresenter(): Representer
    {
        return new Representer(this);
    }
}

export default Publication;
