import SinglePublicationPreview from '../../../modules/ui/pages/SinglePublicationPreview';
import withGlobalAppLayout from '../../../modules/ui/containers/HOC/withGlobalAppLayout';
import { ReactElement } from 'react';

export default function index(props: any): ReactElement
{
    return withGlobalAppLayout(SinglePublicationPreview, {
        ...props,
        locale: props.pageContext.locale,
        hideGfLogo: false,
    });
}
