import ExtendableError from '../ExtendableError';

class ApiError extends ExtendableError
{
    public status: number;
    public errors: any;

    constructor(
        message: string,
        status: number = 500,
        errors?: any,
    )
    {
        super(message);
        this.status = status;
        this.errors = errors;
    }
}

export default ApiError;
