/* eslint-disable no-undef */
import HttpHeaderConstructor from '../HttpHeaderConstructor';
import Module from '../../../application/Service/Module';
import ApiError from '../../../domain/Common/Exception/ApiError';
import axios, { Axios, AxiosRequestHeaders } from 'axios';

/**
 * @todo [refactor] this entire client needs to be rewritten.
 */
class HttpClient
{
    public constructor(
        private axiosClient: Axios = axios,
        private globalLocation: Location = Module.get().location,
    ) {}

    public async fetchFrom<IReturnDataType = any>(
        apiUrl: string,
        authToken?: string,
        headers?: AxiosRequestHeaders,
    ): Promise<IReturnDataType>
    {
        const response = await this.axiosClient.get(apiUrl, {
            headers: HttpClient.getHeadersForRequest(
                authToken,
                headers,
            ),
        });

        if (response.status > 299)
        {
            throw new ApiError(response.statusText, response.status, response.data.meta && response.data.meta.errors);
        }

        return response.data;
    }

    public reload(): void
    {
        this.globalLocation.reload();
    }

    private static getHeadersForRequest(
        bearerAuthorization?: string,
        headers?: AxiosRequestHeaders,
    ): AxiosRequestHeaders
    {
        return {
            ...HttpHeaderConstructor.create()
                .withAcceptApplicationJson()
                .withContentTypeApplicationJson()
                .withOptionalBearerAuthorization(bearerAuthorization)
                .get(),
            ...(headers && headers),
        };
    }
}

export default HttpClient;
