/* eslint-disable no-undef */
import { TLocale } from '../../Type/Locale';
import { AxiosRequestHeaders } from 'axios';

class HttpHeaderConstructor
{
    private readonly headers: AxiosRequestHeaders;

    private constructor()
    {
        this.headers = {};
    }

    public static create(): HttpHeaderConstructor
    {
        return new this();
    }

    public static buildForDefaultPostRequestHeaders(): AxiosRequestHeaders
    {
        return new this()
            .withAcceptApplicationJson()
            .withContentTypeApplicationJson()
            .get();
    }

    public withAccept(acceptDataType: string): HttpHeaderConstructor
    {
        this.headers['Accept'] = acceptDataType;

        return this;
    }

    public withAcceptLanguage(language: TLocale): HttpHeaderConstructor
    {
        this.headers['Accept-Language'] = language;

        return this;
    }

    public withContentType(contentType: string): HttpHeaderConstructor
    {
        this.headers['Content-Type'] = contentType;

        return this;
    }

    public withApiVersion(apiVersion: string): HttpHeaderConstructor
    {
        this.headers['X-API-VERSION'] = apiVersion;

        return this;
    }

    public withOptionalApiVersion(apiVersion?: string): HttpHeaderConstructor
    {
        if (apiVersion)
        {
            this.withApiVersion(apiVersion);
        }

        return this;
    }

    public withAcceptApplicationJson(): HttpHeaderConstructor
    {
        this.withAccept('application/json');

        return this;
    }

    public withContentTypeApplicationJson(): HttpHeaderConstructor
    {
        this.withContentType('application/json');

        return this;
    }

    public withOptionalBearerAuthorization(token?: string): HttpHeaderConstructor
    {
        if (token)
        {
            this.headers['Authorization'] = `Bearer ${token}`;
        }

        return this;
    }

    public get(): AxiosRequestHeaders
    {
        return this.headers;
    }
}

export default HttpHeaderConstructor;
