import HttpClient from '../../Network/HttpClient';
import parameters from '../../../application/_config/parameters';
import { PublicationFromApiInterface } from '../../../domain/Publication/PublicationFromAPI';
import Publication from '../../../domain/Publication/Publication';
import { TLocale } from '../../Type/Locale';

class PublicationClient
{
    public constructor(
        private httpClient: HttpClient = new HttpClient(),
        private rootApiUrl: string = parameters.gfApiUrl,
    )
    {
    }

    public async getPublicationById(publicationId: string, locale: TLocale): Promise<Publication>
    {
        const fullApiResponse = await this.httpClient
            .fetchFrom(`${this.rootApiUrl}/v1/publications/${publicationId}?locale=${locale || 'de'}`);

        const rawPublication: PublicationFromApiInterface = fullApiResponse.resources;

        return Publication.Factory().fromApiFullStructure(rawPublication);
    }

    public async getLatestPublication(locale: TLocale): Promise<Publication>
    {
        const fullApiResponse = await this.httpClient
            .fetchFrom(`${this.rootApiUrl}/v1/publication/latest?locale=${locale || 'de'}`);

        const publicationDTO: PublicationFromApiInterface = fullApiResponse.resources;

        return Publication.Factory().fromApiFullStructure(publicationDTO);
    }
}

export default PublicationClient;
